import { styled } from '@mui/material/styles';
import NextLink from 'next/link';
import React from 'react';
import type { NextLinkComposedProps } from '../types/link';
const Anchor = styled('a')({});
const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed({
  to,
  linkAs,
  href,
  replace,
  scroll,
  shallow,
  prefetch,
  locale,
  ...other
}, ref) {
  return <NextLink href={to} prefetch={prefetch} as={linkAs} replace={replace} scroll={scroll} shallow={shallow} passHref locale={locale} legacyBehavior>
      <Anchor ref={ref} {...other} />
    </NextLink>;
});
export default NextLinkComposed;