import { Avatar } from '@magicschool/ui/Avatar';
import { Box } from '@magicschool/ui/Box';
import { Stack } from '@magicschool/ui/Stack';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ButtonBase from '@mui/material/ButtonBase';
import { useTheme } from '@mui/material/styles';
import { useStore } from 'features/store';
import type React from 'react';
import LocalizationSection from './LocalizationSection';
import ProfileSection from './ProfileSection';
type HeaderProps = {
  hideProfileSection?: boolean;
  showLogout?: boolean;
  logoutAction?: () => Promise<void> | void;
};
const Header: React.FC<HeaderProps> = ({
  hideProfileSection,
  showLogout,
  logoutAction
}) => {
  const toggleDrawer = useStore(s => s.SideBarStoreData.toggleDrawer, []);
  const theme = useTheme();
  return <Stack flex={1} direction="row" px={1} justifyContent="space-between" alignItems="center" data-sentry-element="Stack" data-sentry-component="Header" data-sentry-source-file="index.tsx">
      <Box sx={theme => ({
      height: 35,
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: 'auto'
      }
    })} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <ButtonBase onClick={toggleDrawer} data-sentry-element="ButtonBase" data-sentry-source-file="index.tsx">
          <Avatar variant="rounded" size="medium" color="secondary" clickable sx={{
          ...theme.typography.commonAvatar,
          overflow: 'hidden'
        }} data-sentry-element="Avatar" data-sentry-source-file="index.tsx">
            <MenuRoundedIcon data-sentry-element="MenuRoundedIcon" data-sentry-source-file="index.tsx" />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{
      flexGrow: 1
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx" />

      <Stack direction="row" gap={!hideProfileSection ? 2 : 1} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Box sx={{
        display: 'block'
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <LocalizationSection data-sentry-element="LocalizationSection" data-sentry-source-file="index.tsx" />
        </Box>
        {!hideProfileSection && <ProfileSection />}
        {showLogout && <ButtonBase onClick={logoutAction}>
            <Avatar variant="rounded" size="medium" clickable color="secondary" sx={{
          marginLeft: 1,
          overflow: 'hidden'
        }}>
              <LogoutRounded />
            </Avatar>
          </ButtonBase>}
      </Stack>
    </Stack>;
};
export default Header;