import { space } from '@magicschool/ui/constants/space';
import { styled } from '@mui/material/styles';
interface MainStyleProps {
  open: boolean;
}
const drawerWidth = 260;
export const Main = styled('main', {
  shouldForwardProp: prop => prop !== 'open'
})<MainStyleProps>(({
  theme,
  open
}) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  ...(!open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter + 200
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 72),
      width: `calc(100% - ${drawerWidth}px)`
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter + 200
    }),
    marginLeft: 0,
    width: `calc(100% - ${drawerWidth}px)`
  }),
  [theme.breakpoints.down('md')]: {
    padding: space(2),
    minHeight: 'calc(100vh - 80px)',
    marginTop: space(8),
    ...(!open && {
      width: `calc(100% - ${drawerWidth}px)`
    })
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: space(10),
    padding: space(2),
    ...(!open && {
      width: `calc(100% - ${drawerWidth}px)`
    })
  }
}));