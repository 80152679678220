import NextLinkComposed from '@/components/NextLinkComposed';
import MuiLink from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import React from 'react';
import type { LinkProps } from '../types/link';
const Anchor = styled('a')({});
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link({
  activeClassName = 'active',
  as: linkAs,
  className: classNameProps,
  href,
  noLinkStyle,
  role,
  ...other
}, ref) {
  const pathname = usePathname();
  const hrefPathname = typeof href === 'string' ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: pathname === hrefPathname && activeClassName
  });
  const isExternal = typeof href === 'string' && (href.startsWith('http') || href.startsWith('mailto:'));
  if (isExternal) {
    if (noLinkStyle) {
      return <Anchor className={className} href={href} ref={ref} {...other} />;
    }
    return <MuiLink className={className} href={href} ref={ref} {...other} />;
  }
  if (noLinkStyle) {
    return <NextLinkComposed className={className} ref={ref} to={href} {...other} />;
  }
  return <MuiLink component={NextLinkComposed} linkAs={linkAs} className={className} ref={ref} to={href} {...other} />;
});
export default Link;