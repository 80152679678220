import Drawer from '@mui/material/Drawer';
// material-ui
import { type CSSObject, type Theme, styled } from '@mui/material/styles';

// project import
import { zIndices } from '@magicschool/ui/constants/zIndices';
const drawerWidth = 260;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  borderRight: 'none',
  zIndex: zIndices.overlay,
  background: theme.palette.background.default,
  overflowX: 'hidden',
  boxShadow: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen + 200
  })
});
const closedMixin = (theme: Theme): CSSObject => ({
  borderRight: 'none',
  zIndex: zIndices.overlay,
  background: theme.palette.background.default,
  overflowX: 'hidden',
  width: 72,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen + 200
  })
});

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({
  theme,
  open
}) => ({
  width: drawerWidth,
  borderRight: '0px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));
export default MiniDrawerStyled;