import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';
import { useStore } from 'features/store';
import { useEffect, useState } from 'react';
import Link from '@/components/Link';
import { analyticsTrack } from '@/util/analytics';
import { radius } from '@magicschool/ui/constants/radius';
import { zIndices } from '@magicschool/ui/constants/zIndices';
import ButtonBase from '@mui/material/ButtonBase';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePathname } from 'next/navigation';
import type { LinkTarget, NavItemType } from 'types';
interface NavItemProps {
  item: NavItemType;
  isSub?: boolean;
}
const NavLinkItem = ({
  item,
  isSub
}: NavItemProps) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [isSelected, setIsSelected] = useState(false);
  const pathname = usePathname();
  const toggleDrawer = useStore(s => s.SideBarStoreData.toggleDrawer, []);
  const {
    isToolPristine,
    isToolConfigPristine,
    resetEditToolStore
  } = useStore(({
    EditToolStoreData: s
  }) => ({
    isToolPristine: s.isToolPristine,
    resetEditToolStore: s.reset,
    isToolConfigPristine: s.isToolConfigPristine
  }), []);
  const safeNavigate = (): boolean => {
    if (isToolPristine() && isToolConfigPristine()) return true;
    const shouldLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?');
    if (shouldLeave) resetEditToolStore();
    return shouldLeave;
  };
  useEffect(() => {
    setIsSelected(pathname === item.url || pathname === item.altUrl);
  }, [pathname, item.url]);
  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }
  const itemHandler = () => {
    // ADMINTODO: this doesn't seem to be working properly
    analyticsTrack('Navigation:Action', {
      location: 'leftNav',
      value: item.id
    });
    if (matchDownMd) toggleDrawer();
    return safeNavigate();
  };
  return <ListItemButton component={Link} href={item.url!} target={itemTarget} sx={{
    zIndex: zIndices.overlay,
    borderRadius: radius[1],
    color: '#444',
    mb: 0.5,
    ml: isSub ? 2 : 0,
    '&:hover': {
      background: theme.palette.secondary.light
    },
    '&.Mui-selected': {
      background: theme.palette.secondary.light,
      color: 'secondary.main',
      '&:hover': {
        color: 'secondary.main',
        background: theme.palette.secondary.light
      }
    }
  }} selected={isSelected} onClick={itemHandler} data-test-id={item.dataTestId} data-sentry-element="ListItemButton" data-sentry-component="NavLinkItem" data-sentry-source-file="index.tsx">
      <ButtonBase sx={{
      borderRadius: radius[1]
    }} aria-label="theme-icon" data-sentry-element="ButtonBase" data-sentry-source-file="index.tsx">
        <ListItemIcon sx={{
        minWidth: 36,
        color: isSelected ? 'secondary.main' : 'text.secondary'
      }} data-sentry-element="ListItemIcon" data-sentry-source-file="index.tsx">{item.icon}</ListItemIcon>
      </ButtonBase>
      <ListItemText primary={<Typography fontWeight={500} variant={isSelected ? 'h5' : 'body1'} color="inherit">
            {item.title}
          </Typography>} data-sentry-element="ListItemText" data-sentry-source-file="index.tsx" />
    </ListItemButton>;
};
const NavWithChild = ({
  item
}: NavItemProps) => {
  const theme = useTheme();
  const pathname = usePathname();
  const [isSelected, setIsSelected] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (pathname?.startsWith(item.url!)) {
      setIsSelected(true);
      setOpen(true);
    }
  }, [pathname, item.url]);
  return <div id={`${item.id}-page`} data-test-id={item.dataTestId} data-sentry-component="NavWithChild" data-sentry-source-file="index.tsx">
      <ListItemButton sx={{
      zIndex: zIndices.overlay,
      borderRadius: radius[1],
      color: '#444',
      mb: 0.5,
      '&:hover': {
        background: theme.palette.secondary.light
      },
      '&.Mui-selected': {
        background: theme.palette.secondary.light,
        color: 'secondary.main',
        '&:hover': {
          color: 'secondary.main',
          background: theme.palette.secondary.light
        }
      }
    }} selected={isSelected} onClick={() => setOpen(!open)} data-sentry-element="ListItemButton" data-sentry-source-file="index.tsx">
        <ButtonBase sx={{
        borderRadius: radius[1]
      }} aria-label="theme-icon" data-sentry-element="ButtonBase" data-sentry-source-file="index.tsx">
          <ListItemIcon sx={{
          minWidth: 36,
          color: isSelected ? 'secondary.main' : 'text.secondary'
        }} data-sentry-element="ListItemIcon" data-sentry-source-file="index.tsx">{item.icon}</ListItemIcon>
        </ButtonBase>
        <ListItemText primary={<Typography fontWeight={500} variant={isSelected ? 'h5' : 'body1'} color="inherit">
              {item.title}
            </Typography>} data-sentry-element="ListItemText" data-sentry-source-file="index.tsx" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} data-sentry-element="Collapse" data-sentry-source-file="index.tsx">
        {item.children?.map(child => <NavItem isSub={true} key={child.id} item={child} />)}
      </Collapse>
    </div>;
};
const NavItem = ({
  item,
  isSub = false
}: NavItemProps) => {
  return <div id={`${item.id}-page`} data-sentry-component="NavItem" data-sentry-source-file="index.tsx">{item.children ? <NavWithChild item={item} /> : <NavLinkItem isSub={isSub} item={item} />}</div>;
};
export default NavItem;